import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import hubspotForms from './hubspotForms'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${hubspotForms}

  :root {
    --banner-height: 0px;
  }
  
  html {
    font-size: 62.5%;
  }
  
  * {
    box-sizing: border-box;
  }

  *,
  body {
    font-size: 1.6rem;
    font-family: ${theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    text-decoration: none;
    display: inline-block;
  }
  
  .p-section {
    scroll-margin-top: calc(60px + var(--banner-height));
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }
`

export default GlobalStyle
