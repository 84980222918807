import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 3.6, // rem
    outerMargin: 1.8, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 78, // rem
      md: 102.4, // rem
      lg: 123.6, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48.75, // em
      md: 64, // em
      lg: 77.5, // em
    },
  },
  breakpoints: ['48.75em', '64em', '77.5em'], //make sure to keep the same values as above
  fonts: {
    body: 'Lexend, sans-serif',
    hoves: 'Hoves, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semi: 600,
    bold: 700,
  },
  colors: {
    black: '#000',
    white: '#FFF',
    dark: '#0C0A30',
    darkShade: '#504F6A',
    purpleBlue: '#7101FF',
    purpleBlueLight: '#F6F0FF',
    purple: '#8B01E8',
    purpleShade: '#AB48EE',
    peach: '#F89090',
    peachShade: '#FAAFAF',
    lightBlue: '#6DD8FC',
    lightBlueShade: '#E8F9FF',
    navy: '#464867',
    lightNavy: '#5F659F',
    lightNavyShade: '#F2F3F7',
    lightPurple: '#A2B1FB',
    red: '#AD284B',
    yellow: '#FFD080',
    green: '#38D9BF',
    greyLight: '#F0F0F3',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64],
  radii: {
    default: 4,
    small: 8,
    regular: 16,
    medium: 24,
    large: 32,
    circle: 99999,
  },
}

export default theme
