import { generateMedia } from 'styled-media-query'
import theme from './theme'

const breakpoints = theme.flexboxgrid.breakpoints

//To get same breakpoints as react-styled-flexboxgrid
//Breakpoints are in em
const customBreakpoints: { [key: string]: string } = {}
for (const [key, value] of Object.entries(breakpoints)) {
  customBreakpoints[key] = `${value}em`
}

const customMedia = generateMedia(customBreakpoints)

export default customMedia
