import React, { useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'
import loadable from '@loadable/component'
import qs from 'qs'
//Types
import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby'
import { IModal } from '../types/modal'
//Components
const Modal = loadable(() => import('../components/commons/Modal'))
//Hooks
import { useLocation } from '@reach/router'
// Theme
import theme, { GlobalStyle } from '../theme'

type LayoutWrapperProps = PageProps

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  const [modalId, setModalId] = useState<string | undefined>(undefined)
  const location = useLocation()

  const dataStatic = useStaticQuery(graphql`
    query ModalsQuery {
      allDatoCmsModal {
        edges {
          node {
            ...modalFragment
          }
        }
      }
    }
  `)
  useEffect(() => {
    const queryParams = qs.parse(location.search.substring(1))
    setModalId(queryParams.modalId ? queryParams.modalId.toString() : undefined)
    return () => undefined
  }, [location])

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      if (modalId) {
        body.style.overflow = 'hidden'
      } else {
        body.style.overflow = ''
      }
    }
  }, [modalId])

  const modals: IModal[] = useMemo(() => {
    return dataStatic.allDatoCmsModal.edges.map(({ node }: { node: IModal }) => node)
  }, [dataStatic])

  function closeModal() {
    navigate(-1)
  }
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        {modals.map((modal) => {
          return <Modal key={modal.id} showModal={modalId === modal.modalId} data={modal} onRequestClose={closeModal} />
        })}
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default LayoutWrapper
